<template>
  <div class="view-task">
    <!-- 条件搜索 -->
    <div class="task-search">
      <template v-if="activeKey === 0">
        <a-button @click="handleSyncVehicleMap" class="search-item" :disabled="isDownloading">同步车端仓库</a-button>
        <a-button @click="handleSyncGitMap" class="search-item" :loading="isSyncing">同步云端仓库</a-button>
      </template>
      <template v-if="activeKey === 1">
        <a-radio-group v-model="queryParam.taskType" button-style="solid" @change="handleTaskTypeChange">
          <a-radio-button v-for="(d, index) in taskTypeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
        <a-input-search
          class="margin-left task-template-name"
          placeholder="模糊搜索任务模板名称"
          v-model="queryParam.taskName"
          @search="handleViewTaskByName"
          allowClear
        />
        <a-button type="primary" @click="handleAddTaskTemplate" class="search-item">新增任务模板</a-button>
        <a-button @click="handleSyncTask" class="search-item">同步车端任务</a-button>
      </template>
      <template v-if="activeKey === 2">
        <a-radio-group v-model="queryParam.schedulerType" button-style="solid" @change="handleSchedulerType">
          <a-radio-button v-for="(d, index) in schedulerTypeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
        <!-- <a-input-search class="span-margin-left" placeholder="模糊搜索任务名称" v-model="queryParam.taskName" @search="handleViewTaskByName" allowClear /> -->
        <a-range-picker
          class="margin-left"
          :show-time="{ format: 'HH:mm' }"
          format="YYYY-MM-DD HH:mm"
          value-format="YYYY-MM-DD HH:mm"
          :placeholder="['任务开始时间', '任务结束时间']"
          v-model="startTimeRange"
          @change="handlelTaskTime"
          @ok="handleStartTimeRangeOk"
        />
        <a-button type="primary" @click="addExecTask" class="search-item">新增执行任务</a-button>
      </template>
      <template v-if="activeKey === 3">
        <a-button @click="handleResetTaskSetting" class="search-item"><a-icon type="setting" />返回固定点任务设置</a-button
        >
        <a-button @click="syncResetTask" class="search-item">同步车端返回固定点任务设置</a-button>
      </template>
    </div>
    <a-toolbar :loading="loading" refresh @refreshQuery="getTaskList" :tid="activeKey + ''">
      <div class="table-operations">
        <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getTaskList"
        /> -->
      </div>
    </a-toolbar>
    <!-- 数据展示 -->
    <a-tabs v-model="activeKey">
      <a-tab-pane :key="0" tab="地图仓库" v-if="isCanDowloadMap">
        <download-map
          @onDownloading="isDownloading = $event"
          :vehicleId="params.vehicleId"
          ref="mapRef"
          @onLoading="handleSync"
          :tabKey="activeKey"
        />
      </a-tab-pane>
      <a-tab-pane :key="1" tab="任务模板">
        <!-- 在车端创建的任务模板，告诉车端mapId与mapVersion等来创建一个任务，taskId是车端创建的任务Id -->
        <task-template-list
          :withDynamic="withDynamic"
          :info="info"
          :vehicleId="params.vehicleId"
          ref="taskTemplateListRef"
          @viewRoute="toViewRoute"
        />
      </a-tab-pane>
      <a-tab-pane :key="2" tab="执行任务">
        <!-- 告诉车端执行任务模板中的哪个任务，主要包括手动任务与定时任务 -->
        <exec-task-list
          :withDynamic="withDynamic"
          :info="info"
          :vehicleId="params.vehicleId"
          ref="execTaskListRef"
          @navigateTo="handleNavigateTo"
          @viewRoute="toViewRoute"
        />
      </a-tab-pane>
      <a-tab-pane :key="3" tab="返回固定点任务" v-if="withDynamic">
        <!-- 告诉车端执行任务模板中的哪个固定点任务-->
        <reset-task-list
          :info="info"
          :vehicleId="params.vehicleId"
          ref="resetTaskListRef"
          @navigateTo="handleNavigateTo"
          @viewRoute="toViewRoute"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { Tabs } from 'ant-design-vue'
import TaskBase from './TaskBase.js'
import ExecTaskList from './ExecTaskList'
import TaskTemplateList from './TaskTemplateList'
import DownloadMap from './DownloadMap'
import ResetTaskList from './ResetTaskList'
export default {
  mixins: [TaskBase],
  name: 'ViewTaskModal',
  components: { ExecTaskList, TaskTemplateList, ATabs: Tabs, ATabPane: Tabs.TabPane, DownloadMap, ResetTaskList },
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    position: {
      type: Object,
      default: () => {}
    },
    isShowModal: {
      type: Boolean,
      default: () => {}
    },
    vehicleId: {
      type: String,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    // 节流控制
    this.handleViewTaskByName = debounce(this.handleViewTaskByName, 500)
    return {
      isDownloading: true,
      isSyncing: false,
      ModalVisible: this.isShowModal,
      loading: false,
      taskStateOptions: [],
      // 日期范围
      startTimeRange: [],
      // 路由名称
      searchRouteName: '',
      // 查询参数
      queryParam: {
        vehicleId: this.vehicleId,
        taskType: null,
        taskName: '',
        remark: '',
        taskState: '',
        pageNum: 1,
        pageSize: 10,
        schedulerType: null
      },
      total: 0,
      routePlanVisible: false,
      curRouteId: '',
      title: this.vehicleId + '-任务管理',
      TaskDetailVisible: false,
      taskId: '',
      taskForm: null,
      isGetData: false,
      // 任务周期
      schedulerTypeOptions: [
        {
          dictLabel: '全部',
          dictValue: null
        },
        {
          dictLabel: '手动任务',
          dictValue: 2
        },
        {
          dictLabel: '单次任务',
          dictValue: 0
        },
        {
          dictLabel: '每日任务',
          dictValue: 1
        }
        // {
        //   dictLabel: '动态点位任务',
        //   dictValue: 3
        // }
      ],
      // 任务类型
      taskTypeOptions: [
        {
          dictLabel: '全部',
          dictValue: null
        },
        {
          dictLabel: 'LaneId任务',
          dictValue: '1'
        },
        {
          dictLabel: '循迹任务',
          dictValue: '0'
        }
        // {
        //   dictLabel: '固定点任务',
        //   dictValue: '2'
        // }
      ],
      activeKey: 2
    }
  },
  computed: {
    // 是否能下载地图
    isCanDowloadMap() {
      let hasPermit = true
      const value = ['tenant:iot:map:list']
      const allPermission = '*:*:*'
      const permissions = this.$store.getters && this.$store.getters.permissions
      if (value && value instanceof Array && value.length > 0) {
        const permissionFlag = value
        const hasPermissions = permissions.some((permission) => {
          return allPermission === permission || permissionFlag.includes(permission)
        })
        if (!hasPermissions) {
          hasPermit = false
        }
      } else {
        hasPermit = false
      }
      console.log('isCanDowloadMap', hasPermit)
      return hasPermit
    },
    // 是否能下载地图
    withDynamic() {
      console.log('withDynamic')
      let hasPermit = true
      const value = ['tenant:iot:task:dynamic']
      const allPermission = '*:*:*'
      const permissions = this.$store.getters && this.$store.getters.permissions
      if (value && value instanceof Array && value.length > 0) {
        const permissionFlag = value
        const hasPermissions = permissions.some((permission) => {
          return allPermission === permission || permissionFlag.includes(permission)
        })
        if (!hasPermissions) {
          hasPermit = false
        }
      } else {
        hasPermit = false
      }
      console.log('withDynamic', hasPermit)
      return hasPermit
    }
  },
  created() {
    this.isGetData = true
    this.getData()
    this.setDynamicData()
    console.log('params', this.params)
  },
  watch: {
    // 当前车辆Id发生变化时
    'info.vehicleId'(newVal, oldVal) {
      console.log('任务查看窗口关闭', newVal + '=' + oldVal)
      this.$emit('closeTaskModal')
      this.ModalVisible = false
    },
    isShowModal(newVal, oldVal) {
      console.log('isShowModal', newVal)
    }
  },
  methods: {
    setDynamicData() {
      if (this.withDynamic) {
        this.schedulerTypeOptions.push({
          dictLabel: '动态点位任务',
          dictValue: 3
        })
        this.taskTypeOptions.push({
          dictLabel: '固定点任务',
          dictValue: '2'
        })
      }
    },
    handleSync(e) {
      this.isSyncing = e
    },
    handleSyncVehicleMap() {
      this.$refs.mapRef.handleSyncVehicleMap()
    },
    handleSyncGitMap() {
      this.$refs.mapRef.handleSyncGitMap()
    },
    handlelTaskTime(date, dateString) {
      console.log(date, dateString)
      if (date.length === 0) {
        this.queryParam.params = {}
        this.queryParam.vehicleId = this.vehicleId
        this.$refs.execTaskListRef.getTaskList(this.queryParam)
      }
    },
    handleUpdate() {
      this.getTaskList()
    },
    updateSendTask(record) {
      this.taskForm = record
    },
    addExecTask() {
      this.$refs.execTaskListRef.addTimedTask({})
    },
    handleResetTaskSetting() {
      this.$refs.resetTaskListRef.resetTaskSetting({})
    },
    syncResetTask() {
      this.$refs.resetTaskListRef.syncResetTask({})
    },
    handleCloseSendTaskModal() {},
    async getData() {},
    getListData() {
      if (this.params && typeof this.params === 'object') {
        this.vehicleId = this.params.vehicleId
        this.queryParam.vehicleId = this.vehicleId
        // 页面返回，同时不要求页面数据更新，则不重新读取数据
        if (this.params.navigateType === 'back' && !this.params.navigateBackUpdate) {
        }
      }
    },

    handleCloseTaskModal() {
      this.clearTimeoutEx()
      this.$emit('closeViewTaskModal')
    },
    // 查看任务详情关闭
    handleCloseTaskDetailModal() {
      this.TaskDetailVisible = false
    },
    handleViewTaskBytaskState() {
      console.log('tasktaskState', this.queryParam.tasktaskState)
      this.getTaskList()
    },
    handleViewTaskByName(value) {
      console.log('handleViewTaskByName', value)
      this.queryParam.taskName = value
      this.$refs.taskTemplateListRef.getTaskList(this.queryParam)
    },
    handleTaskTypeChange() {
      this.$refs.taskTemplateListRef.getTaskList(this.queryParam)
    },
    getTaskList() {
      if (this.activeKey === 0) {
        this.$refs.mapRef.getList()
      } else if (this.activeKey === 1) {
        this.$refs.taskTemplateListRef.getTaskList(this.queryParam)
      } else if (this.activeKey === 2) {
        this.$refs.execTaskListRef.getTaskList(this.queryParam)
      } else if (this.activeKey === 3) {
        this.$refs.resetTaskListRef.getTaskList(this.queryParam)
      }
    },
    handleCloseRoutePlanModal() {
      this.routePlanVisible = false
    },
    handleChangeRoute(record) {
      this.curRouteId = record.taskRouteId
      this.routePlanVisible = true
      console.log('handleChangeRoute')
    },
    toViewRoute(routeId) {
      console.log('toViewRoute')
      // 传递给Nav组件
      this.$emit('moduleEvent', 'viewRoute', routeId)
    },
    handleNavigateTo(page, params) {
      this.$emit('navigateTo', page, params)
    },
    handleStartTimeRangeOk(value) {
      console.log('handleStartTimeRangeOk', value)
      this.queryParam.params = {}
      this.queryParam.vehicleId = this.vehicleId
      if (Array.isArray(this.startTimeRange) && this.startTimeRange.length === 2) {
        if (this.startTimeRange[0]) {
          this.queryParam.params['beginStartTime'] = this.startTimeRange[0]
        }
        if (this.startTimeRange[1]) {
          this.queryParam.params['endStartTime'] = this.startTimeRange[1]
        }
      }
      this.$refs.execTaskListRef.getTaskList(this.queryParam)
    },
    handleSchedulerType(e) {
      this.$refs.execTaskListRef.getTaskList(this.queryParam)
    },
    handleAddTaskTemplate() {
      this.$refs.taskTemplateListRef.createTaskTemplate()
    },
    handleSyncTask() {
      this.$refs.taskTemplateListRef.handleSyncTask()
    }
  },
  beforeDestroy() {
    console.log('ViewTaskModal', 'beforeDestroy')
    this.isClearTimeout = true
    this.clearTimeoutEx()
  },
  activated() {
    // 页面激活
    console.log('viewTask-activated', this.params)
    this.info = this.params.info
    // 返回如何控制是否需要刷新页面
    this.getListData()
  },
  deactivated() {
    console.log('viewTask-deactivated', this.params)
    this.isClearTimeout = true
    this.clearTimeoutEx()
  }
}
</script>
<style lang="less">
.task-name {
  span {
    display: block;
  }
  & > span:nth-child(1) {
    margin-bottom: 5px;
    font-weight: bold;
  }
}
.view-task {
  .route-name {
    text-decoration: underline;
  }
  .task-header {
    display: inline;
  }
  .margin-left {
    margin-left: 10px;
  }
  .task-template-name {
    width: 260px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
  }
  .table-operations {
    margin-bottom: 0;
    position: absolute;
    right: 15px;
    z-index: 1;
  }
}
.task-result {
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-task {
  .vxe-modal--wrapper .vxe-modal--content > div {
    height: auto;
  }
  .ant-spin-nested-loading {
    height: 100% !important;
  }
}
.task-search {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  .search-item {
    margin-left: 10px;
  }
  .ant-radio-group {
    min-width: 250px;
  }
}
</style>
