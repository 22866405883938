var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-task" },
    [
      _c(
        "div",
        { staticClass: "task-search" },
        [
          _vm.activeKey === 0
            ? [
                _c(
                  "a-button",
                  {
                    staticClass: "search-item",
                    attrs: { disabled: _vm.isDownloading },
                    on: { click: _vm.handleSyncVehicleMap },
                  },
                  [_vm._v("同步车端仓库")]
                ),
                _c(
                  "a-button",
                  {
                    staticClass: "search-item",
                    attrs: { loading: _vm.isSyncing },
                    on: { click: _vm.handleSyncGitMap },
                  },
                  [_vm._v("同步云端仓库")]
                ),
              ]
            : _vm._e(),
          _vm.activeKey === 1
            ? [
                _c(
                  "a-radio-group",
                  {
                    attrs: { "button-style": "solid" },
                    on: { change: _vm.handleTaskTypeChange },
                    model: {
                      value: _vm.queryParam.taskType,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParam, "taskType", $$v)
                      },
                      expression: "queryParam.taskType",
                    },
                  },
                  _vm._l(_vm.taskTypeOptions, function (d, index) {
                    return _c(
                      "a-radio-button",
                      { key: index, attrs: { value: d.dictValue } },
                      [_vm._v(_vm._s(d.dictLabel))]
                    )
                  }),
                  1
                ),
                _c("a-input-search", {
                  staticClass: "margin-left task-template-name",
                  attrs: {
                    placeholder: "模糊搜索任务模板名称",
                    allowClear: "",
                  },
                  on: { search: _vm.handleViewTaskByName },
                  model: {
                    value: _vm.queryParam.taskName,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParam, "taskName", $$v)
                    },
                    expression: "queryParam.taskName",
                  },
                }),
                _c(
                  "a-button",
                  {
                    staticClass: "search-item",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleAddTaskTemplate },
                  },
                  [_vm._v("新增任务模板")]
                ),
                _c(
                  "a-button",
                  {
                    staticClass: "search-item",
                    on: { click: _vm.handleSyncTask },
                  },
                  [_vm._v("同步车端任务")]
                ),
              ]
            : _vm._e(),
          _vm.activeKey === 2
            ? [
                _c(
                  "a-radio-group",
                  {
                    attrs: { "button-style": "solid" },
                    on: { change: _vm.handleSchedulerType },
                    model: {
                      value: _vm.queryParam.schedulerType,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParam, "schedulerType", $$v)
                      },
                      expression: "queryParam.schedulerType",
                    },
                  },
                  _vm._l(_vm.schedulerTypeOptions, function (d, index) {
                    return _c(
                      "a-radio-button",
                      { key: index, attrs: { value: d.dictValue } },
                      [_vm._v(_vm._s(d.dictLabel))]
                    )
                  }),
                  1
                ),
                _c("a-range-picker", {
                  staticClass: "margin-left",
                  attrs: {
                    "show-time": { format: "HH:mm" },
                    format: "YYYY-MM-DD HH:mm",
                    "value-format": "YYYY-MM-DD HH:mm",
                    placeholder: ["任务开始时间", "任务结束时间"],
                  },
                  on: {
                    change: _vm.handlelTaskTime,
                    ok: _vm.handleStartTimeRangeOk,
                  },
                  model: {
                    value: _vm.startTimeRange,
                    callback: function ($$v) {
                      _vm.startTimeRange = $$v
                    },
                    expression: "startTimeRange",
                  },
                }),
                _c(
                  "a-button",
                  {
                    staticClass: "search-item",
                    attrs: { type: "primary" },
                    on: { click: _vm.addExecTask },
                  },
                  [_vm._v("新增执行任务")]
                ),
              ]
            : _vm._e(),
          _vm.activeKey === 3
            ? [
                _c(
                  "a-button",
                  {
                    staticClass: "search-item",
                    on: { click: _vm.handleResetTaskSetting },
                  },
                  [
                    _c("a-icon", { attrs: { type: "setting" } }),
                    _vm._v("返回固定点任务设置"),
                  ],
                  1
                ),
                _c(
                  "a-button",
                  {
                    staticClass: "search-item",
                    on: { click: _vm.syncResetTask },
                  },
                  [_vm._v("同步车端返回固定点任务设置")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "a-toolbar",
        {
          attrs: { loading: _vm.loading, refresh: "", tid: _vm.activeKey + "" },
          on: { refreshQuery: _vm.getTaskList },
        },
        [_c("div", { staticClass: "table-operations" })]
      ),
      _c(
        "a-tabs",
        {
          model: {
            value: _vm.activeKey,
            callback: function ($$v) {
              _vm.activeKey = $$v
            },
            expression: "activeKey",
          },
        },
        [
          _vm.isCanDowloadMap
            ? _c(
                "a-tab-pane",
                { key: 0, attrs: { tab: "地图仓库" } },
                [
                  _c("download-map", {
                    ref: "mapRef",
                    attrs: {
                      vehicleId: _vm.params.vehicleId,
                      tabKey: _vm.activeKey,
                    },
                    on: {
                      onDownloading: function ($event) {
                        _vm.isDownloading = $event
                      },
                      onLoading: _vm.handleSync,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-tab-pane",
            { key: 1, attrs: { tab: "任务模板" } },
            [
              _c("task-template-list", {
                ref: "taskTemplateListRef",
                attrs: {
                  withDynamic: _vm.withDynamic,
                  info: _vm.info,
                  vehicleId: _vm.params.vehicleId,
                },
                on: { viewRoute: _vm.toViewRoute },
              }),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: 2, attrs: { tab: "执行任务" } },
            [
              _c("exec-task-list", {
                ref: "execTaskListRef",
                attrs: {
                  withDynamic: _vm.withDynamic,
                  info: _vm.info,
                  vehicleId: _vm.params.vehicleId,
                },
                on: {
                  navigateTo: _vm.handleNavigateTo,
                  viewRoute: _vm.toViewRoute,
                },
              }),
            ],
            1
          ),
          _vm.withDynamic
            ? _c(
                "a-tab-pane",
                { key: 3, attrs: { tab: "返回固定点任务" } },
                [
                  _c("reset-task-list", {
                    ref: "resetTaskListRef",
                    attrs: { info: _vm.info, vehicleId: _vm.params.vehicleId },
                    on: {
                      navigateTo: _vm.handleNavigateTo,
                      viewRoute: _vm.toViewRoute,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }